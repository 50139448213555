//================================================================
// CSS SEMANTIC COLOR VARIABLES
// Semantic color variables should be defined with color primitives.
// New semantic color variables need to be added to all themes.
// ---------------------------------------------------------------
// Primitive color variables can be found at ./primitivecolors.scss
//================================================================

// CSS variables

:root {
  // Forground Colors
  --fg-default: var(--gray-900); // Default text, onHover text, onHover icons
  --fg-muted: var(--gray-600); // Secondary text, default icons
  --fg-subtle: var(--gray-400); // Placeholder and disabled text, subtle icons
  --fg-onEmphasis: var(--white); // Foreground elements on top of emphasis backgrounds
  --fg-accent: var(--accent-500); // Used for primary actions
  --fg-accent-hover: var(--accent-600); // Used for primary actions on hover
  --fg-info: var(--blue-500); // All named forgrounds are used for text and icons in that context
  --fg-info-hover: var(--blue-600);
  --fg-new: var(--purple-500);
  --fg-new-hover: var(--purple-600);
  --fg-success: var(--green-500);
  --fg-success-hover: var(--green-600);
  --fg-attention: var(--amber-700);
  --fg-attention-hover: var(--amber-800);
  --fg-danger: var(--red-500);
  --fg-danger-hover: var(--red-600);
  --fg-household: var(--indigo-500);
  --fg-household-hover: var(--indigo-600);
  --fg-registration: var(--teal-500);
  --fg-registration-hover: var(--teal-600);
  --fg-account: var(--yellow-500);
  --fg-account-hover: var(--yellow-600);
  --fg-asset: var(--orange-500);
  --fg-asset-hover: var(--orange-600);
  --fg-transaction: var(--gray-500);
  --fg-transaction-hover: var(--gray-600);
  --fg-product: var(--yellow-500);
  --fg-product-hover: var(--yellow-600);
  --fg-representative: var(--blue-500);
  --fg-representative-hover: var(--blue-600);
  --fg-billing: var(--green-500);
  --fg-billing-hover: var(--green-600);
  --fg-disabled: var(--gray-400);

  // Background Colors
  --bg-default: var(--white); // Used for default backgrounds
  --bg-default-hover: var(--gray-100); // Used for default backgrounds on hover
  --bg-muted: var(--gray-200); // Used for tertiary backgrounds
  --bg-subtle: var(--gray-50); // Used for secondary backgrounds
  --bg-subtle-hover: var(--gray-200); // Used for secondary backgrounds on hover
  --bg-accent: var(--accent-50); // Light accent used for primary actions
  --bg-accent-hover: var(--accent-100); // Light accent used for primary actions on hover
  --bg-info: var(--blue-50); // All named backgrounds are used for backgrounds in that context
  --bg-info-hover: var(--blue-100);
  --bg-new: var(--purple-50);
  --bg-new-hover: var(--purple-100);
  --bg-success: var(--green-50);
  --bg-success-hover: var(--green-100);
  --bg-attention: var(--amber-50);
  --bg-attention-hover: var(--amber-100);
  --bg-danger: var(--red-50);
  --bg-danger-hover: var(--red-100);
  --bg-household: var(--indigo-50);
  --bg-household-hover: var(--indigo-100);
  --bg-registration: var(--teal-50);
  --bg-registration-hover: var(--teal-100);
  --bg-account: var(--yellow-50);
  --bg-account-hover: var(--yellow-100);
  --bg-asset: var(--orange-50);
  --bg-asset-hover: var(--orange-100);
  --bg-transaction: var(--gray-50);
  --bg-transaction-hover: var(--gray-100);
  --bg-product: var(--yellow-50);
  --bg-product-hover: var(--yellow-100);
  --bg-representative: var(--blue-50);
  --bg-representative-hover: var(--blue-100);
  --bg-billing: var(--green-50);
  --bg-billing-hover: var(--green-100);
  --bg-highlight: var(--yellow-50);
  --bg-emphasis: var(--gray-600); // Used for inverted backgrounds
  --bg-emphasis-hover: var(--gray-700); // Used for inverted backgrounds on hover
  --bg-accent-emphasis: var(--accent-500); // Used for primary actions. Foreground elements should use onEmphasis.
  --bg-accent-emphasis-hover: var(--accent-600); // Used for primary actions on hover
  --bg-info-emphasis: var(--blue-500);
  --bg-info-emphasis-hover: var(--blue-600);
  --bg-new-emphasis: var(--purple-500);
  --bg-new-emphasis-hover: var(--purple-600);
  --bg-success-emphasis: var(--green-500);
  --bg-success-emphasis-hover: var(--green-600);
  --bg-attention-emphasis: var(--amber-500);
  --bg-attention-emphasis-hover: var(--amber-600);
  --bg-danger-emphasis: var(--red-500);
  --bg-danger-emphasis-hover: var(--red-600);
  --bg-notification-emphasis: var(--red-500);
  --bg-notification-emphasis-hover: var(--red-600);
  --bg-online-emphasis: var(--green-500);
  --bg-online-emphasis-hover: var(--green-600);
  --bg-offline-emphasis: var(--gray-100);
  --bg-offline-emphasis-hover: var(--gray-200);
  --bg-away-emphasis: var(--gray-300);
  --bg-away-emphasis-hover: var(--gray-400);
  --bg-household-emphasis: var(--indigo-500);
  --bg-household-emphasis-hover: var(--indigo-600);
  --bg-registration-emphasis: var(--teal-500);
  --bg-registration-emphasis-hover: var(--teal-600);
  --bg-account-emphasis: var(--yellow-500);
  --bg-account-emphasis-hover: var(--yellow-600);
  --bg-asset-emphasis: var(--orange-500);
  --bg-asset-emphasis-hover: var(--orange-600);
  --bg-transaction-emphasis: var(--gray-500);
  --bg-transaction-emphasis-hover: var(--gray-600);
  --bg-product-emphasis: var(--yellow-500);
  --bg-product-emphasis-hover: var(--yellow-600);
  --bg-representative-emphasis: var(--blue-500);
  --bg-representative-emphasis-hover: var(--blue-600);
  --bg-billing-emphasis: var(--green-500);
  --bg-billing-emphasis-hover: var(--green-600);
  --bg-mask: rgba(0, 0, 0, 0.4); // Used for modal mask
  --bg-disabled: var(--gray-100);
  --bg-disabled-emphasis: var(--gray-400);

  // Border Colors
  --border-default: var(--gray-300); // Used for default borders
  --border-muted: var(--gray-100); // Used for secondary borders
  --border-onEmphasis: var(--white); // Used for borders on top of emphasis backgrounds
  --border-accent: var(--accent-300); // Used for primary actions
  --border-info: var(--blue-300); // All named borders are used for borders in that context
  --border-new: var(--purple-300);
  --border-success: var(--green-300);
  --border-attention: var(--amber-300);
  --border-danger: var(--red-300);
  --border-household: var(--indigo-300);
  --border-registration: var(--teal-300);
  --border-account: var(--yellow-300);
  --border-asset: var(--orange-300);
  --border-transaction: var(--gray-300);
  --border-product: var(--yellow-300);
  --border-representative: var(--blue-300);
  --border-billing: var(--green-300);
  --border-emphasis: var(--gray-500); // Used for emphasized borders (active, selected, etc.)
  --border-emphasis-hover: var(
    --gray-600
  ); // Used for emphasized borders on hover and borders on top of emphasis backgrounds
  --border-accent-emphasis: var(--accent-500);
  --border-accent-emphasis-hover: var(--accent-600);
  --border-info-emphasis: var(--blue-500);
  --border-info-emphasis-hover: var(--blue-600);
  --border-new-emphasis: var(--purple-500);
  --border-new-emphasis-hover: var(--purple-600);
  --border-success-emphasis: var(--green-500);
  --border-success-emphasis-hover: var(--green-600);
  --border-attention-emphasis: var(--amber-500);
  --border-attention-emphasis-hover: var(--amber-600);
  --border-danger-emphasis: var(--red-500);
  --border-danger-emphasis-hover: var(--red-600);
  --border-household-emphasis: var(--indigo-500);
  --border-household-emphasis-hover: var(--indigo-600);
  --border-registration-emphasis: var(--teal-500);
  --border-registration-emphasis-hover: var(--teal-600);
  --border-account-emphasis: var(--yellow-500);
  --border-account-emphasis-hover: var(--yellow-600);
  --border-asset-emphasis: var(--orange-500);
  --border-asset-emphasis-hover: var(--orange-600);
  --border-transaction-emphasis: var(--gray-500);
  --border-transaction-emphasis-hover: var(--gray-600);
  --border-product-emphasis: var(--yellow-500);
  --border-product-emphasis-hover: var(--yellow-600);
  --border-representative-emphasis: var(--blue-500);
  --border-representative-emphasis-hover: var(--blue-600);
  --border-billing-emphasis: var(--green-500);
  --border-billing-emphasis-hover: var(--green-600);

  //Data viz
  --data-color-01: #360597;
  --data-color-02: #4422ff;
  --data-color-03: #0dcb76;
  --data-color-04: #08e4cb;
  --data-color-05: #076538;
  --data-color-06: #b879ff;
  --data-color-07: #05718a;
  --data-color-08: #5ce0ab;
  --data-color-09: #4ef3e3;
  --data-color-10: #5780ff;
}

///////////////////////////
// Dark Mode
///////////////////////////

[data-theme='dark'] {
  // Forground Colors
  --fg-default: var(--gray-100); // Default text, onHover text, onHover icons
  --fg-muted: var(--gray-300); // Secondary text, default icons
  --fg-subtle: var(--gray-500); // Placeholder and disabled text, subtle icons
  --fg-onEmphasis: var(--white); // Foreground elements on top of emphasis backgrounds
  --fg-accent: var(--accent-200); // Used for primary actions
  --fg-accent-hover: var(--accent-100); // Used for primary actions on hover
  --fg-info: var(--blue-200); // All named forgrounds are used for text and icons in that context
  --fg-info-hover: var(--blue-100);
  --fg-new: var(--purple-200);
  --fg-new-hover: var(--purple-100);
  --fg-success: var(--green-200);
  --fg-success-hover: var(--green-100);
  --fg-attention: var(--amber-200);
  --fg-attention-hover: var(--amber-100);
  --fg-danger: var(--red-200);
  --fg-danger-hover: var(--red-100);
  --fg-household: var(--indigo-200);
  --fg-household-hover: var(--indigo-100);
  --fg-registration: var(--teal-200);
  --fg-registration-hover: var(--teal-100);
  --fg-account: var(--yellow-200);
  --fg-account-hover: var(--yellow-100);
  --fg-asset: var(--orange-200);
  --fg-asset-hover: var(--orange-100);
  --fg-transaction: var(--gray-200);
  --fg-transaction-hover: var(--gray-100);
  --fg-product: var(--yellow-200);
  --fg-product-hover: var(--yellow-100);
  --fg-representative: var(--blue-200);
  --fg-representative-hover: var(--blue-100);
  --fg-billing: var(--green-200);
  --fg-billing-hover: var(--green-100);
  --fg-disabled: var(--gray-500);

  // Background Colors
  --bg-default: var(--gray-900); // Used for default backgrounds
  --bg-default-hover: var(--gray-800); // Used for default backgrounds on hover
  --bg-muted: var(--gray-700); // Used for tertiary backgrounds
  --bg-subtle: var(--gray-800); // Used for secondary backgrounds
  --bg-subtle-hover: var(--gray-700); // Used for secondary backgrounds on hover
  --bg-accent: var(--accent-900); // Light accent used for primary actions
  --bg-accent-hover: var(--accent-800); // Light accent used for primary actions on hover
  --bg-info: var(--blue-900); // All named backgrounds are used for backgrounds in that context
  --bg-info-hover: var(--blue-800);
  --bg-new: var(--purple-900);
  --bg-new-hover: var(--purple-800);
  --bg-success: var(--green-900);
  --bg-success-hover: var(--green-800);
  --bg-attention: var(--amber-900);
  --bg-attention-hover: var(--amber-800);
  --bg-danger: var(--red-900);
  --bg-danger-hover: var(--red-800);
  --bg-household: var(--indigo-900);
  --bg-household-hover: var(--indigo-800);
  --bg-registration: var(--teal-900);
  --bg-registration-hover: var(--teal-800);
  --bg-account: var(--yellow-900);
  --bg-account-hover: var(--yellow-800);
  --bg-asset: var(--orange-900);
  --bg-asset-hover: var(--orange-800);
  --bg-transaction: var(--gray-900);
  --bg-transaction-hover: var(--gray-800);
  --bg-product: var(--yellow-900);
  --bg-product-hover: var(--yellow-800);
  --bg-representative: var(--blue-900);
  --bg-representative-hover: var(--blue-800);
  --bg-billing: var(--green-900);
  --bg-billing-hover: var(--green-800);
  --bg-highlight: var(--yellow-500);
  --bg-emphasis: var(--gray-500); // Used for inverted backgrounds
  --bg-emphasis-hover: var(--gray-600); // Used for inverted backgrounds on hover
  --bg-accent-emphasis: var(--accent-500); // Used for primary actions. Foreground elements should use onEmphasis.
  --bg-accent-emphasis-hover: var(--accent-600); // Used for primary actions on hover
  --bg-info-emphasis: var(--blue-500);
  --bg-info-emphasis-hover: var(--blue-600);
  --bg-new-emphasis: var(--purple-500);
  --bg-new-emphasis-hover: var(--purple-600);
  --bg-success-emphasis: var(--green-500);
  --bg-success-emphasis-hover: var(--green-600);
  --bg-attention-emphasis: var(--amber-700);
  --bg-attention-emphasis-hover: var(--amber-800);
  --bg-danger-emphasis: var(--red-500);
  --bg-danger-emphasis-hover: var(--red-600);
  --bg-notification-emphasis: var(--red-500);
  --bg-notification-emphasis-hover: var(--red-600);
  --bg-online-emphasis: var(--green-500);
  --bg-online-emphasis-hover: var(--green-600);
  --bg-offline-emphasis: var(--gray-800);
  --bg-offline-emphasis-hover: var(--gray-900);
  --bg-away-emphasis: var(--gray-600);
  --bg-away-emphasis-hover: var(--gray-700);
  --bg-household-emphasis: var(--indigo-500);
  --bg-household-emphasis-hover: var(--indigo-600);
  --bg-registration-emphasis: var(--teal-500);
  --bg-registration-emphasis-hover: var(--teal-600);
  --bg-account-emphasis: var(--yellow-500);
  --bg-account-emphasis-hover: var(--yellow-600);
  --bg-asset: var(--orange-500);
  --bg-asset-hover: var(--orange-600);
  --bg-transaction: var(--gray-500);
  --bg-transaction-hover: var(--gray-600);
  --bg-product: var(--yellow-500);
  --bg-product-hover: var(--yellow-600);
  --bg-representative: var(--blue-500);
  --bg-representative-hover: var(--blue-600);
  --bg-billing: var(--green-500);
  --bg-billing-hover: var(--green-600);
  --bg-mask: rgba(0, 0, 0, 0.4); // Used for modal mask
  --bg-disabled: var(--gray-700);
  --bg-disabled-emphasis: var(--gray-500);

  // Border Colors
  --border-default: var(--gray-600); // Used for default borders
  --border-muted: var(--gray-700); // Used for secondary borders
  --border-onEmphasis: var(--900); // Used for borders on top of emphasis backgrounds
  --border-accent: var(--accent-700); // Used for primary actions
  --border-info: var(--blue-700); // All named borders are used for borders in that context
  --border-new: var(--purple-700);
  --border-success: var(--green-700);
  --border-attention: var(--amber-700);
  --border-danger: var(--red-700);
  --border-household: var(--indigo-700);
  --border-registration: var(--teal-700);
  --border-account: var(--yellow-700);
  --border-asset: var(--orange-700);
  --border-transaction: var(--gray-700);
  --border-product: var(--yellow-700);
  --border-representative: var(--blue-700);
  --border-billing: var(--green-700);
  --border-emphasis: var(--gray-500); // Used for emphasized borders (active, selected, etc.)
  --border-emphasis-hover: var(
    --gray-600
  ); // Used for emphasized borders on hover and borders on top of emphasis backgrounds
  --border-accent-emphasis: var(--accent-500);
  --border-accent-emphasis-hover: var(--accent-600);
  --border-info-emphasis: var(--blue-500);
  --border-info-emphasis-hover: var(--blue-600);
  --border-new-emphasis: var(--purple-500);
  --border-new-emphasis-hover: var(--purple-600);
  --border-success-emphasis: var(--green-500);
  --border-success-emphasis-hover: var(--green-600);
  --border-attention-emphasis: var(--amber-700);
  --border-attention-emphasis-hover: var(--amber-800);
  --border-danger-emphasis: var(--red-500);
  --border-danger-emphasis-hover: var(--red-600);
  --border-household-emphasis: var(--indigo-500);
  --border-household-emphasis-hover: var(--indigo-600);
  --border-registration-emphasis: var(--teal-500);
  --border-registration-emphasis-hover: var(--teal-600);
  --border-account-emphasis: var(--yellow-500);
  --border-account-emphasis-hover: var(--yellow-600);
  --border-asset-emphasis: var(--orange-500);
  --border-asset-emphasis-hover: var(--orange-600);
  --border-transaction-emphasis: var(--gray-500);
  --border-transaction-emphasis-hover: var(--gray-600);
  --border-product-emphasis: var(--yellow-500);
  --border-product-emphasis-hover: var(--yellow-600);
  --border-representative-emphasis: var(--blue-500);
  --border-representative-emphasis-hover: var(--blue-600);
  --border-billing-emphasis: var(--green-500);
  --border-billing-emphasis-hover: var(--green-600);

  //Data viz
  --data-color-01: #360597;
  --data-color-02: #4422ff;
  --data-color-03: #0dcb76;
  --data-color-04: #08e4cb;
  --data-color-05: #076538;
  --data-color-06: #b879ff;
  --data-color-07: #05718a;
  --data-color-08: #5ce0ab;
  --data-color-09: #4ef3e3;
  --data-color-10: #5780ff;
}

//================================================================
// CSS PRIMITIVE COLOR VARIABLES
// DO NOT use color primitives in your styles, as it will not follow theme definitions.
// DO use color primitives to define semantic tokens, as these will follow theme definitions.
// ---------------------------------------------------------------
// Semantic color variables can be found at ./semanticcolors.scss
//================================================================

// CSS variables

:root {
  --black: #000000;
  --white: #ffffff;

  --transparent: rgba(0, 0, 0, 0);

  // TODO: How do accents work with custom colors?
  --accent-50: var(--blue-50);
  --accent-100: var(--blue-100);
  --accent-200: var(--blue-200);
  --accent-300: var(--blue-300);
  --accent-400: var(--blue-400);
  --accent-500: var(--blue-500);
  --accent-600: var(--blue-600);
  --accent-700: var(--blue-700);
  --accent-800: var(--blue-800);
  --accent-900: var(--blue-900);

  --gray-50: #f9f9fa;
  --gray-100: #f4f4f5;
  --gray-200: #e8e8eb;
  --gray-300: #d0d0d5;
  --gray-400: #9394a0;
  --gray-500: #696a79;
  --gray-600: #4f505e;
  --gray-700: #3b3c47;
  --gray-800: #2b2c35;
  --gray-900: #1f1f26;

  --blue-50: #f3f9ff;
  --blue-100: #eaf4ff;
  --blue-200: #d7e9ff;
  --blue-300: #add3ff;
  --blue-400: #009bf1;
  --blue-500: #006eb6;
  --blue-600: #005292;
  --blue-700: #003c76;
  --blue-800: #022b5f;
  --blue-900: #031d4a;

  --red-50: #fff8f8;
  --red-100: #fff1f2;
  --red-200: #ffe2e3;
  --red-300: #ffc1c4;
  --red-400: #ff5c68;
  --red-500: #d70026;
  --red-600: #a60016;
  --red-700: #80000a;
  --red-800: #610104;
  --red-900: #480202;

  --teal-50: #dcfffb;
  --teal-100: #bdfffa;
  --teal-200: #4ffffa;
  --teal-300: #00e7e7;
  --teal-400: #00a5a9;
  --teal-500: #00767d;
  --teal-600: #005a62;
  --teal-700: #00434c;
  --teal-800: #01313a;
  --teal-900: #02232b;

  --green-50: #e0fff0;
  --green-100: #c7ffe4;
  --green-200: #7dffc8;
  --green-300: #00eca5;
  --green-400: #00a973;
  --green-500: #007a50;
  --green-600: #005d3a;
  --green-700: #004629;
  --green-800: #01341c;
  --green-900: #022513;

  --indigo-50: #f6f8ff;
  --indigo-100: #f0f2ff;
  --indigo-200: #e2e6ff;
  --indigo-300: #c6cdff;
  --indigo-400: #798bff;
  --indigo-500: #3755ff;
  --indigo-600: #002bf2;
  --indigo-700: #0300d0;
  --indigo-800: #190398;
  --indigo-900: #18056d;

  --purple-50: #faf6ff;
  --purple-100: #f6f0ff;
  --purple-200: #ede3ff;
  --purple-300: #dac6ff;
  --purple-400: #ad78ff;
  --purple-500: #8d29ff;
  --purple-600: #6b00d8;
  --purple-700: #4e00ac;
  --purple-800: #360386;
  --purple-900: #240465;

  --fuchsia-50: #fff5fd;
  --fuchsia-100: #ffeefb;
  --fuchsia-200: #ffdef7;
  --fuchsia-300: #ffbaf0;
  --fuchsia-400: #ff3adf;
  --fuchsia-500: #c300a8;
  --fuchsia-600: #970080;
  --fuchsia-700: #740062;
  --fuchsia-800: #570249;
  --fuchsia-900: #400235;

  --orange-50: #fff6f5;
  --orange-100: #ffefed;
  --orange-200: #ffe1dd;
  --orange-300: #ffc1b9;
  --orange-400: #ff5f32;
  --orange-500: #c33900;
  --orange-600: #972800;
  --orange-700: #761a00;
  --orange-800: #5a1101;
  --orange-900: #430a02;

  --amber-50: #fffbeb;
  --amber-100: #fef3c7;
  --amber-200: #fde68a;
  --amber-300: #fcd34d;
  --amber-400: #fbbf24;
  --amber-500: #f59e0b;
  --amber-600: #d97706;
  --amber-700: #b45309;
  --amber-800: #92400e;
  --amber-900: #78350f;

  --yellow-50: #fefce8;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;
}

:root {
  // Font families
  --fontStack-sansSerif: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';

  // Font size
  --text-display-size: 2.5rem; // 40px
  --text-title-size-large: 2rem; // 32px
  --text-title-size-medium: 1.5rem; // 24px
  --text-title-size-small: 1rem; // 16px
  --text-subtitle-size: 1.25rem; // 20px
  --text-body-size-large: 1rem; // 16px
  --text-body-size-medium: 0.875rem; // 14px
  --text-body-size-small: 0.75rem; // 12px
  --text-caption-size: 0.75rem; // 12px

  // Line height
  --text-display-lineHeight: 3rem;
  --text-title-lineHeight-large: 2.5rem;
  --text-title-lineHeight-medium: 2rem;
  --text-title-lineHeight-small: 1.5rem;
  --text-subtitle-lineHeight: 1.75rem;
  --text-body-lineHeight-large: 1.5rem;
  --text-body-lineHeight-medium: 1.25rem;
  --text-body-lineHeight-small: 1rem;
  --text-caption-lineHeight: 1rem;

  // Font weight
  --text-display-weight: var(--base-text-weight-medium);
  --text-title-weight-large: var(--base-text-weight-semibold);
  --text-title-weight-medium: var(--base-text-weight-semibold);
  --text-title-weight-small: var(--base-text-weight-semibold);
  --text-subtitle-weight: var(--base-text-weight-regular);
  --text-caption-weight: var(--base-text-weight-regular);

  --base-text-weight-light: 300;
  --base-text-weight-regular: 400;
  --base-text-weight-medium: 500;
  --base-text-weight-semibold: 600;

  // Font shorthand
  --text-display: var(--text-display-weight) var(--text-display-size) / var(--text-display-lineHeight)
    var(--fontStack-sansSerif);
  --text-title-large: var(--text-title-weight-large) var(--text-title-size-large) / var(--text-title-lineHeight-large)
    var(--fontStack-sansSerif);
  --text-title-medium: var(--text-title-weight-medium) var(--text-title-size-medium) /
    var(--text-title-lineHeight-medium) var(--fontStack-sansSerif);
  --text-title-small: var(--text-title-weight-small) var(--text-title-size-small) / var(--text-title-lineHeight-small)
    var(--fontStack-sansSerif);
  --text-subtitle: var(--text-subtitle-weight) var(--text-subtitle-size) / var(--text-subtitle-lineHeight)
    var(--fontStack-sansSerif);
  --text-body-large: var(--base-text-weight-medium) var(--text-body-size-large) / var(--text-body-lineHeight-large)
    var(--fontStack-sansSerif);
  --text-body-medium: var(--base-text-weight-medium) var(--text-body-size-medium) / var(--text-body-lineHeight-medium)
    var(--fontStack-sansSerif);
  --text-body-small: var(--base-text-weight-medium) var(--text-body-size-small) / var(--text-body-lineHeight-small)
    var(--fontStack-sansSerif);
  --text-caption: var(--text-caption-weight) var(--text-caption-size) / var(--text-caption-lineHeight)
    var(--fontStack-sansSerif);
}

.p-tree {

	.p-tree-container {

		.p-treenode {
			padding: 0 0 .5rem 0;

			&:focus > .p-treenode-content {

			}

			.p-treenode-content {
				padding: .25rem .25rem;

				.p-tree-toggler {
					margin-right: .25rem;
				}

				.p-treenode-icon {
				}

				.p-checkbox {

					.p-indeterminate {
						.p-checkbox-icon {
						}
					}

                    &.p-variant-filled {
                        .p-checkbox-box {
                
                            &.p-highlight {
                            }
                        }
                
                        &:not(.p-disabled) {
                            .p-checkbox-box {
                                &:hover {
                                }
                
                                &.p-highlight:hover {
                                }
                            }
                        }
                    }
				}

				&.p-highlight {

					.p-tree-toggler,
					.p-treenode-icon {

						&:hover {
						}
					}
				}

				&.p-treenode-selectable:not(.p-highlight):hover {
				}

				&.p-treenode-dragover {
				}
			}
		}

		.p-treenode-children {
			padding: 0 .125rem;
	
			p-treenode {

				.p-treenode {
					padding: 0;
				}
	
				.p-treenode-content {
					padding: .125rem .125rem;
					
					&:hover:not(:disabled),
					&:focus-visible {
						color: var(--primary-color-text);
						background-color: var(--primary-color);
						cursor: pointer;
					}

					&:focus {
						outline: transparent;
						box-shadow: none;
					}
				}
			}
		}
	}

	.p-tree-filter-container {

		.p-tree-filter {
		}

		.p-tree-filter-icon {
		}
	}



	.p-tree-loading-icon {

		&.p-icon {
		}
	}

	.p-treenode-droppoint {
		&.p-treenode-droppoint-active {
		}
	}

	&.p-tree-horizontal {
		.p-treenode {
			.p-treenode-content {
				&.p-highlight {

					.p-treenode-icon {
					}
				}

				.p-tree-toggler {

				}

				.p-treenode-icon {
				}

				.p-checkbox {
				}

				.p-treenode-label:not(.p-highlight):hover {
				}

				&.p-treenode-selectable:not(.p-highlight):hover {
				}

				&:focus {
				}
			}
		}
	}
}

:root {
  --outline-focus-offset: 1px;
  --outline-focus-width: 2px;
}

:root {
  // Focus and validation
  --outline-focus: solid var(--outline-focus-width) var(--border-accent-emphasis);
  --outline-success: solid var(--outline-focus-width) var(--border-success-emphasis);
  --outline-danger: solid var(--outline-focus-width) var(--border-danger-emphasis);
}

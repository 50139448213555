/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
:root,
html,
:host {
  font-size: 16px !important;
}

body {
  font: var(--text-body-medium);
}

@import "assets/layout/styles/layout/layout.scss";
@import "assets/layout/styles/opl-tokens/index";

@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";

@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";
